<template>
  <div class="item" :title="theDocument.name">
    {{ theDocument.id }} - {{ theDocument.name }}
  </div>
</template>

<script>
import documentMixins from "@/components/documents/mixins/documentMixins";

export default {
  name: "DocNavAnchorLink",
  props: {
    id: String,
    contract: Object,
  },
  mixins: [documentMixins],
  data() {
    return {
      theDocument: {},
    };
  },
  async mounted() {
    await this.getDocumentById(this.id).then(({ data }) => {
      this.theDocument = data;
    });
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.item
  color: #333
  font-size: 13px
  overflow: hidden
  width: 270px
  white-space: nowrap
  text-overflow: ellipsis
  text-transform: uppercase
  cursor: grab
</style>
